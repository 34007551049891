import { Component } from '@angular/core';
import { TemplateService } from '../template.service';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent {
  selectedFile: File | null = null;
  uploadResponse: string | null = null;
  isUploading: boolean = false;
  uploadProgress: number = 0;

  constructor(private templateService: TemplateService) { }

  onFileChange(event: any): void {
    const file = event.target.files[0];
    if (file) {
      this.selectedFile = file;
    }
  }

  onSubmit(): void {
    if (this.selectedFile) {
      this.isUploading = true;
      this.uploadProgress = 0;

      const uploadInterval = setInterval(() => {
        if (this.uploadProgress < 100) {
          this.uploadProgress += 10; // Simulating progress
        } else {
          clearInterval(uploadInterval);
        }
      }, 300); // Adjust the interval for smoother animation

      this.templateService.uploadFile(this.selectedFile).subscribe(
        (response: any) => {
          const sasUrl = response.sas_url;
          this.downloadFile(sasUrl);
          this.isUploading = false;
          this.uploadProgress = 100; // Ensure progress is 100% when done
        },
        (error: any) => {
          this.uploadResponse = 'Erreur lors du téléchargement du fichier';
          console.error(error);
          this.isUploading = false;
          this.uploadProgress = 0;
        }
      );
    }
  }

  downloadFile(sasUrl: string): void {
    const a = document.createElement('a');
    a.href = sasUrl;
    a.download = `${this.selectedFile!.name.replace(/\.[^/.]+$/, "")}_generated.pdf`; // Using non-null assertion operator
    a.click();
  }
}
