import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  private apiUrl = '/api/svc2/template'; // Local backend URL
  private sasTokenUrl = '/api/svc2/generate-sas-token'; // Local SAS token URL

  constructor(private http: HttpClient) { }

  uploadFile(file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post(this.apiUrl, formData, {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    });
  }

  generateSasToken(blobName: string): Observable<any> {
    return this.http.post(this.sasTokenUrl, { blob_name: blobName });
  }
}

